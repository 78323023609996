.App {
  padding: 10px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: rgb(44, 47, 51) !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #1abc9c;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bg-dark {
    background-color:rgb(44, 47, 51) !important;
}
body {
    background-color:rgb(44, 47, 51) !important;
    color: #1abc9c;
}

@media (min-width: 1600px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1590px;
  }
}
.sitename {
 text-align: center;
 color: #CEDBDC;
}

.bigblock {
  box-shadow: -12px -12px 20px #363D47, 10px 10px 24px #1F2327;
  border-radius: 20px;
  padding: 40px;
  margin: 10px;
  border: none;
  background-color: #2c2f33!important;
  min-width:120px;
}
.midblock {
  box-shadow: -12px -12px 20px #363D47, 10px 10px 24px #1F2327;
  border-radius: 20px;
  padding: 10px;
  margin: 10px;
  border: none;
  background-color: #2c2f33!important;
  min-width:120px;
}
.downblock {
  background: #2C2F33;
  box-shadow: inset -6.22302px -6.22302px 6.22302px #363E48, inset 3.73381px 3.73381px 6.22302px #000000;
  border-radius: 12.446px; 
  padding: 6px;
  overflow: hidden;
}
.greenbutton {
  background: #11CBA6;
  box-shadow: -12px -12px 20px #343C46, 10px 10px 20px #1A1D22;
  border-radius: 16px;
  min-width: 160px;
}
.w-100{
  font-size: 0.7rem;
}
.rc-slider-handle {
    width: 28px;
    height: 28px;
    margin-top: -7px;
}
.rc-slider-rail, .rc-slider-track {
    height: 14px;
}

.rc-slider-rail {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 2.68%, #FFFFFF 96.87%), #2C2F33;
}

.rc-slider-track {
    left: 0;
    background: linear-gradient(90deg, rgba(17, 203, 166, 0.2) 2.68%, #11CBA6 96.87%), #2C2F33;
}
.slidebox{
    background: #2C2F33;
    box-shadow: -12px -12px 20px #343C46, 10px 10px 20px #1A1D22;
    border-radius: 50px;
    height: 32px;
    margin: 14px;
    padding: 4px;
}
.list-group-item-danger a {
  color:#1abc9c;
}
.transactions{
  padding: 10px;
}
.roundbtn{
    background: #2C2F33;
    box-shadow: -12px -12px 20px #343C46, 10px 10px 20px #1A1D22;
    border-radius: 50px;
    width: 50px;
    color: #11CBA6;
}
.inputspinner{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.inputspinner div {
  margin: 2px 0;
}
.betsize{
      margin: 12px;
}
.list-group-item-danger {
    color: #1abc9c;
}

.list-group-item-secondary {
    color: #ff4d5e;
}
.end {
      text-align: end;
}
.alert {
      display: none;
}
.card {
  border-radius: 20px;
  padding: 0;
  margin: 0;
  border: none;
  background-color: #2c2f33!important;
}
.rpercent{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.nomargin {margin: 0 !important;}
.margin50 {    margin: 50px 0;}
.flexaround {
    display: flex;
    justify-content: space-around;
}
.roundshadow {
    background: #2C2F33;
    box-shadow: inset -12.446px -12.446px 31.1151px #353C46, inset 12.446px 12.446px 31.1151px #22262A;
    border-radius: 50%;
    padding: 12px;
}
.squarebtn{
      background: #2C2F33;
    box-shadow: -12px -12px 20px #343C46, 10px 10px 20px #1A1D22;
    border-radius: 10px;
    color: white;
    margin: 0 10px;
    width: 64px;
    line-height: 0.9;
    font-size: 1rem;
}
.squarebtn span{
    font-size: 0.8rem;
}

.margin20{margin: 50px 0;}
.diceinput {color: white !important;margin: 20px !important;}
.diceinput .MuiInputBase-root {color: white !important;}
.poollists {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    justify-content: space-around;
}
.gameslist{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    justify-content: space-around;
    background: #2c2f33;
    
    border-radius: 12.446px;
    padding: 6px;
    overflow: hidden;
    margin: 24px;
}
.onegame{
    flex: 1;
    margin: 20px;
    display: flex;
    padding: 28px 30px;
    min-width: 480px;
    box-shadow: -12px -12px 20px #363D47, 10px 10px 24px #1F2327;
    min-height: 300px;
    align-items: center;
    border-radius: 20px;
    flex-direction: column;
    justify-content: center;
}

.gamepool{
    flex: 1;
    margin: 20px;
    display: flex;
    padding: 28px 30px;
    min-width: 200px;
    max-width: 320px;
    box-shadow: -12px -12px 20px #363D47, 10px 10px 24px #1F2327;
    height: 300px;
    align-items: center;
    border-radius: 20px;
    flex-direction: column;
    justify-content: center;
}
.gamepoolimg{
  width: 125px;
  height: 125px;
  padding: 20px;
  box-shadow: inset -12.446px -12.446px 31.1151px #353C46, inset 12.446px 12.446px 31.1151px #22262A;
  border-radius: 50%;
}
.gamepoolbut {
    color: #CEDBDC;
    width: 100%;
    cursor: pointer;
    padding: 6px;
    overflow: hidden;
    font-size: 0.83rem;
    box-shadow: -12px -12px 20px #343C46, 10px 10px 20px #1A1D22;
    text-align: center;
    white-space: nowrap;
    border-radius: 10px;
    text-overflow: ellipsis;
    margin: 0 32px;
    margin-top: 32px;
}
.ma30{margin: 20px;}
.textend{    width: 50%;    text-align: end;}

.unipoolbut {
    color: #cedbdc;
    cursor: pointer;
    padding: 6px;
    overflow: hidden;
    font-size: .83rem;
    box-shadow: -12px -12px 20px #343c46, 10px 10px 20px #1a1d22;
    text-align: center;
    white-space: nowrap;
    border-radius: 10px;
    text-overflow: ellipsis;
    margin: 32px;
}
.addwidget{
    text-align: center;
    color: white;
}

@media (max-width: 580px) {
  .onegame{
      min-width: 200px !important;
  }
  .onegame .flexaround{
    display: block !important;
  }
  .onegame .textend{
    width:100% !important;
  }
  .wstr{
    width:100% !important;
    font-size: 0.7rem;
  }
}
